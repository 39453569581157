
import JobsListTable from '@/modules/jobs/components/jobs-list-table.vue';
import JobFormErrorModal from '@/modules/jobs/components/job-form-error-modal.vue';
import FilterJobs from '@/modules/jobs/components/filter-jobs.vue';
import i18n from '@/vueI18n';
import { mapActions, mapGetters} from 'vuex';
import ImageBackground from '@/modules/home/components/image-background.vue'
import firebase from 'firebase/app'
import 'firebase/firestore';
import 'firebase/database'; 
// import moment from 'moment';  
import { AnalyticsManager } from '@/shared/firebase/firebase-analytics';

export default {
    data(){
        return{
            searchJob:'',
            filter:false,
            displayTitle:false,
            // imageBg:"https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fjob-opportunities.webp?alt=media&token=0c413659-4295-4a5d-a1d6-d83e59f949c0",
            // title:this.i18n('drugDeal.services.jobs'),
            // subtitle1:this.i18n('drugDeal.jobs.browse'),
            // subtitle2:this.i18n('drugDeal.jobs.moreInfo'),
            // subtitle3:this.i18n('drugDeal.jobs.find'),
            // btnDesc:this.i18n('drugDeal.jobs.add')
            currentMonth:undefined,
            currentYear:undefined,
            jobMaxCount: undefined,
            addedJobInMonth: 0,
            userJobsInMonth:[],
            userJob: {},
            canNotAdd:false,

            modalErrorVisible: false,
            dialogErrorVisible: false,

            modalVisible: false,
            dialogVisible: false,

            monthNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ]
              
        }
    },
    computed:{
        lang(){
            // debugger    
            return i18n.locale
        },
        isRTL() {
            return localStorage.getItem('language') == 'ar'
        },
        ...mapGetters({
            currentUser: 'auth/currentUser',
            user: 'jobs/list/user',
            userLoading:'auth/userLoading',
            userListener:'auth/userListener',
            loadingAddMoreJobs:'jobs/list/loadingAddMoreJobs',
            loading: 'jobs/list/loading',
            detailsLoading: 'jobs/list/detailsLoading'
        }),
        imageBg(){
            return "https://firebasestorage.googleapis.com/v0/b/drug-deal-6e05f.appspot.com/o/public%2Fjob-opportunities.webp?alt=media&token=0c413659-4295-4a5d-a1d6-d83e59f949c0"
        },
        title(){
            return this.i18n('drugDeal.services.jobs')
        },
        subtitle1(){
            return this.i18n('drugDeal.jobs.browse')
        },
        subtitle2(){
            return this.i18n('drugDeal.jobs.moreInfo')
        },
        subtitle3(){
            return this.i18n('drugDeal.jobs.find')
        },
        btnDesc(){
            return this.i18n('drugDeal.jobs.add')
        },
        
    },
    watch:{
        lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.jobs') 
        },
        // detailsLoading(newval){
        //     console.log('detailsLoading',newval);
        //     // debugger
        // },
        async userListener(newval){
            // console.log('newval userListener',newval.prescription);
            // console.log('oldval userListener',oldval);
            if(this.userListener){
                this.addMoreJobs(newval) 
            }
        }
    },
    components:{
        [JobsListTable.name]: JobsListTable,
        [FilterJobs.name]: FilterJobs,
        [ImageBackground.name]: ImageBackground,
        [JobFormErrorModal.name]: JobFormErrorModal


    },
    methods:{
    i18n(key, args) {
      return this.$t(key, args);
    },
    addNewItem(){
        if(this.canNotAdd){
            // this.modalVisible = true
            // this.dialogVisible = true
            this.modalErrorVisible = true
            this.dialogErrorVisible = true
        }
        else{
            this.$router.push('add-job')
        }
        
    },
    changeJobHeader(value){
        // console.log('changeJobHeader',value)
        this.displayTitle = value
    },
    closeFilter(){
        this.filter = !this.filter
    },
    ...mapActions({
        setHeaderBg :'layout/setHeaderBg',
        setNavbar:'layout/setNavbar',
        setFooter:'layout/setFooter',
        doFetchUser: 'jobs/list/doFetchUser',
        setCurrentRoute: 'jobs/list/setCurrentRoute',
        setLoadingAddMoreJobs: 'jobs/list/setLoadingAddMoreJobs',
        setMountedTable: "jobs/list/setMountedTable",
        resetCursor: "jobs/list/resetCursor",
    }),
    
    openFilter(){
        this.filter = !this.filter
        // console.log('heyy')
    },
    // close(){
    //     // this.prescriptionDialog = false
    //     this.dialogVisible = false;
    //     setTimeout(() => {
    //         this.modalVisible = false;
    //     }, 500);
    // },
    closeErrorModal(){
        // this.prescriptionDialog = false
        this.dialogErrorVisible = false;
        setTimeout(() => {
            this.modalErrorVisible = false;
        }, 500);
    },
    async addMoreJobs(userListener){
        this.setLoadingAddMoreJobs(true)
        this.userJob = userListener.job
        this.addedJobInMonth = 0
        var today = new Date();
        if(this.userJob || this.userJob != undefined){            
        for (const [key] of Object.entries(this.userJob)) {
            var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
            var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            if(key >= firstDay && key < lastDay){
                this.addedJobInMonth = this.addedJobInMonth + 1
            }
        }
        await firebase.database().ref('setting/job').on('value',(snapshot) => {
          this.jobMaxCount = snapshot.val()
            if(this.addedJobInMonth == this.jobMaxCount || this.addedJobInMonth > this.jobMaxCount){
                this.canNotAdd = true
            }
            else{
                this.canNotAdd = false
            }
            // debugger
            this.setLoadingAddMoreJobs(false)

        });
        }
        else{
            this.setLoadingAddMoreJobs(false)
        }
    }
    },
    // mounted(){

        
    
    // },
    async created(){
        document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.services.jobs') 
        this.setFooter(false)
        this.setCurrentRoute(this.$router.currentRoute.name)
        if(this.userListener){
            this.addMoreJobs(this.userListener)
        }
        AnalyticsManager.logScreen('View Job Details', 'JobProfile');
    },
    destroyed(){
        this.setLoadingAddMoreJobs(true)
        this.setMountedTable(false);
        this.resetCursor();
    }
}
